export const environment = {
  env: 'demo',
  production: true,
  domain: 'midatacredito.com',
  gtmPruebas: true,
  validLogin_bc: 'https://demo.buscacredito.midatacredito.com/',
  validLogin_midc: 'https://demo.midatacredito.com/',
  validLogin_pntd: 'https://demo.pontealdia.midatacredito.com/',
  validLogin_vrf: 'https://demo.midatacredito.com/verifica/',
  country: 'CO',
  parametroOTP: '3234',
  parametriaAutoGestion: '3141',
  termCondition: 13,
  codesApps: {
    bc: 'AvI7MZug3dyIZk1JGFNDm+JOX28ruCgtA9I6d71C4Wg=',
    midc: 'oBgBpJ0vwHZekr1yDr85FhATrrBFKaoXVZ6g/CJyvJg=',
    pntd: 'nJ6Xpc/JYe9c/GfHqqeQhhATrrBFKaoXVZ6g/CJyvJg=',
    vrf: 'rGbQbHZM0CRPg4SODmxO20NoEkvN9Vn+w9M3j1Q69Bw='
  },
  urlEndPoints: 'https://demo-usuario.midatacredito.com/',
  APIEndPoints: {
    // Login
    firstAuthn: 'authn-ws/authn/firstAuthn',
    saveSession: 'authn-ws/authn/saveSession',
    // Registro
    registerUser: 'nwuser-ws/new-user-ws/api/v1/user/CO/registerUser',
    // Sesion
    validationSession: 'authn-ws/authn/validateSession',
    signOff: 'authn-ws/authn/signOff',
    // Password
    updateContra: 'authn-ws/authn/updatePassword',
    validateInfoUpPass: 'authn-ws/authn/validateInfoUpPass',
    // Oneshot
    checkPlanPrice: 'vrfycstmr-ws/verify/checkPlanPrice',
    oneshotRequest: 'vrfycstmr-ws/verify/oneshotRequest',
    oneshotBaloto: 'pymnts/validateOneShotCode',
    // Undia
    undia: 'pymnts/pagos',
    // Profile
    updateProfile: 'nwuser-ws/new-user-ws/api/v1/user/updateProfile',
    updateProfileVerified: 'nwuser-ws/new-user-ws/api/v1/user/updateProfileVerified',
    saveUserInfo: 'nwuser-ws/new-user-ws/api/v1/user/saveAditionalInfo',
    getUserSession: 'nwuser-ws/new-user-ws/api/v1/user/getUserSession',
    getUser: 'nwuser-ws/new-user-ws/api/v1/user/getUser',
    // Revisar
    obtainUser: 'nwuser-ws/new-user-ws/api/v1/user/obtainUser',
    // Nuevos términos y condiciones
    findCustomerLog: 'nwuser-ws/termsConditions/findCustomerLog',
    addCustomerLog: 'nwuser-ws/termsConditions/addCustomerLog',
    saveAcceptanceAdvertising: 'nwuser-ws/termsConditions/saveAcceptanceAdvertising',
    // Nuevo SSO
    consultUser: 'nwuser-ws/new-user-ws/api/v1/user/exists',
    secondAuthn: 'authn-ws/authn/secondAuthn',
    questionList: 'authn-ws/authn/listRecoveryQuestions',
    updateSecondAuthn: 'authn-ws/authn/updateSecondAuthn',
    recoveryQuestion: 'authn-ws/authn/recoveryQuestion',
    registerUserIam: 'nwuser-ws/new-user-ws/api/v1/user/registerUserIam',
    addContra: 'nwuser-ws/new-user-ws/api/v1/user/addPassword',
    // INFO ADICIONAL
    sessioninfo: 'ldnfosession/sessioninfo/ecs',
    loadParameters: 'ldnfosession/load-parameter/byKeyApp'
  },
  EvidenteEP: {
    // Evidente contraseña
    passRecovery: 'validation/api/v1/evidente/passRecovery',
    passRecoveryVerify : 'validation/api/v1/evidente/passRecoveryValidateQuestions',
    passRecoveryQuestions: 'validation/api/v1/evidente/passRecoveryQuestions',
    // Evidente + OTP    
    verifyOTPRecoverPass: 'validation/api/v1/evidente/verifyOTPRecoverPass',
    initPassOTP: 'validation/api/v1/evidente/initPassOTP',
    verifyOTP: 'validation/api/v1/evidente/verifyOTP',
    validateOTP: 'validation/api/v1/evidente/validateOTP',
    // Evidente Master o II
    validateUser: 'validation/api/v1/evidente/validateUser', 
    questions: 'validation/api/v1/evidente/questions',
    validateQuesUser: 'validation/api/v1/evidente/validateQuestions', 
    validateQuestionsOTP: 'validation/api/v1/evidente/validateQuestionsOTP',   
    // Validar user
    verifyUser: 'validation/api/v1/evidente/verifyUser',
    // INFO ADICIONAL
    sessioninfo: 'ldnfosession/sessioninfo/ecs'
  },
  VerifyEP: {
    varifySeller: 'http://demo-ac-vrfycstmr-ws.prd-co-sla-datacash.svc.cluster.local:8080/midatacredito/verifica/v1/seller'
  },
    /*Inicio rutas chat datina*/
    urlChatDatina: "assets/scripts/demo/script-datina-midc.js",
    styleDatina: "assets/scripts/demo/style-datina-midc.css",
    /*fin rutas chat datina*/
    /*Inicio Urls documentos legales*/
    urlTerminosYCondiciones : 'https://demo.midatacredito.com/terminos-condiciones',
    urlPoliticaPrivacidad : 'https://demo.midatacredito.com/politicas-privacidad',
    urlAutorizacionObligatoria : 'https://demo.midatacredito.com/autorizacion',
    urlAutorizacionOpcional : 'https://demo.midatacredito.com/autorizacion-opcional',
    /*Fin Urls documentos legales*/
  slotsBanners : {
    loginMIDC : '/22857899675/Test_Ad_Unit_MIDC_SSO',
  }
};
